//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  props: {
    homeFeatured: Object
  }
}

