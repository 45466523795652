//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  props: {
    homeTiles: {
      type: Array,
      required: true
    },
    homeFeatured: {
      type: Object,
      required: true
    }
  },
  computed: {
    topTiles () {
      return this.homeTiles.slice(0, 3)
    },
    bottomTiles () {
      return this.homeTiles.slice(3)
    }
  }
}

