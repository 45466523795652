//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  props: {
    homeTile: Object
  }
}

