//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import seo from '~/mixins/seo'
import carouselSlidesQuery from '~/apollo/queries/home/carousel'
import homeTilesQuery from '~/apollo/queries/home/tiles'
import homeFeaturedQuery from '~/apollo/queries/home/featured'

export default {
  mixins: [seo],
  apollo: {
    carouselSlides: {
      query: carouselSlidesQuery,
      prefetch: true
    },
    homeTiles: {
      query: homeTilesQuery,
      prefetch: true
    },
    homeFeatured: {
      query: homeFeaturedQuery,
      prefetch: true
    }
  },
  watch: {
    carouselSlides () {
      this.randomize()
    }
  },
  methods: {
    randomize () {
      if (this.carouselSlides && this.carouselSlides.data) {
        for (let i = this.carouselSlides.data.length - 1; i > 0; i--) {
          const randomIndex = Math.floor(Math.random() * i)
          const temp = this.carouselSlides.data[i]
          this.$set(this.carouselSlides.data, i, this.carouselSlides.data[randomIndex])
          this.$set(this.carouselSlides.data, randomIndex, temp)
        }
      }
    }
  }
}

