import { render, staticRenderFns } from "./index.vue?vue&type=template&id=1a8887d5&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeCarousel: require('/var/www/motec/website/motec/components/home/Carousel.vue').default,HomeDesktop: require('/var/www/motec/website/motec/components/home/Desktop.vue').default,HomeTablet: require('/var/www/motec/website/motec/components/home/Tablet.vue').default,HomeMobile: require('/var/www/motec/website/motec/components/home/Mobile.vue').default})
