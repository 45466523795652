export default {
  data () {
    return {
      seoTitle: null,
      seoCategory: null
    }
  },
  head () {
    return {
      title: this.getFullSeoTitle,
      meta: [
        { hid: 'description', name: 'description', content: this.getFullSeoTitle },
        { hid: 'og:description', property: 'og:description', content: this.getFullSeoTitle }
      ]
    }
  },
  computed: {
    getFullSeoTitle () {
      if (this.seoCategory) {
        return this.seoTitle ? 'MoTeC - ' + this.seoCategory + ' - ' + this.seoTitle : 'MoTeC - ' + this.seoCategory
      } else {
        return this.seoTitle ? 'MoTeC - ' + this.seoTitle : 'MoTeC'
      }
    }
  }
}
